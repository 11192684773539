<template>
  <div
    class="vPlugLst__wrapper"
    :class="{
      isLoading: !restaurants.value.length && !searchElement.value.length,
    }"
  >
    <vLightPreloader
      v-show="!restaurants.value.length && !searchElement.value.length"
      :fixed="false"
      :transparent="true"
    />
    <div
      v-show="!restaurants.value.length && searchElement.value.length"
      class="vPlugLst__empty"
    >
      Нет результатов попробуйте ввести <br>
      данные ещё раз
    </div>
    <ul class="vPlugLst">
      <li
        v-for="item in restaurants.value"
        :key="item.id"
        class="vPlugLst__item"
        :class="{ active: item.active }"
      >
        {{ item.name }}
        <div
          class="vPlugLst__checkbox"
          :class="{ active: item.active }"
          @click="selectElement(item.id)"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import vLightPreloader from '@/components/v-light-preloader'
export default {
  name: 'VPlugLst',
  components: {
    vLightPreloader,
  },
  inject: ['restaurants', 'searchElement'],
  props: {
    selectedElements: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  emits: ['selectElement'],
  methods: {
    selectElement(id) {
      this.$emit('selectElement', id)
    },
  },
}
</script>

<style lang="scss" scoped>
.vPlugLst {
  max-height: 205px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 9px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #6764ff;
    border-radius: 20px;
    border: 3px solid #fff;
  }
  &__empty {
    text-align: center;
    color: #80869a;
  }
  &__item {
    background: #f7f8fa;
    border-radius: 7px;
    padding: 12px;
    color: #80869a;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    &.active {
      background: #f8f9fe;
      color: #010d35;
    }
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
  &__checkbox {
    border: 1.5px solid #dcdee3;
    background: #ffffff;
    width: 20px;
    height: 20px;
    border-radius: 6px;
    cursor: pointer;
    &.active {
      border-color: #6764ff;
      background: url('~@/assets/images/checkbox-new.svg') no-repeat center
        #6764ff;
      background-size: contain;
    }
  }
  &__wrapper {
    position: relative;
    &.isLoading {
      min-height: 200px;
    }
  }
}
</style>
