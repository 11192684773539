<template>
  <div class="vPlugLoader">
    <img
      src="~@/assets/images/preloader.gif"
      class="vPlugLoader__img"
    >
    {{ text }}
  </div>
</template>

<script>
export default {
  name: 'VPlugLoader',
  props: {
    text: {
      type: String,
      default: 'Вносим изменения',
    },
  },
}
</script>

<style lang="scss" scoped>
.vPlugLoader {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #dcdee3;
  opacity: 0.9;
  backdrop-filter: blur(26.5px);
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #010d35;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  flex-direction: column;
  &__img {
    width: 85px;
    height: 85px;
  }
}
</style>
