<template>
  <ul class="vPlugFilterList">
    <li
      class="vPlugFilterList__item"
      :class="{ active: activeFilter.value === 'all' }"
      @click="selectByFilter('all')"
    >
      <div class="vPlugFilterList__ico">
        <img src="~@/assets/images/tables-ico.svg">
      </div>
      <div class="vPlugFilterList__title">
        Выбрать
      </div>
      <div class="vPlugFilterList__info">
        <span class="vPlugFilterList__type">все:</span>
        <span
          class="vPlugFilterList__count"
        >{{ allElementsCount.value }} шт</span>
      </div>
    </li>
    <li
      class="vPlugFilterList__item"
      :class="{ active: activeFilter.value === 'b2p' }"
      @click="selectByFilter('b2p')"
    >
      <div class="vPlugFilterList__ico">
        <img src="~@/assets/images/b2p-ico.svg">
      </div>
      <div class="vPlugFilterList__title">
        Выбрать только
      </div>
      <div class="vPlugFilterList__info">
        <span class="vPlugFilterList__type">Best2pay:</span>
        <span class="vPlugFilterList__count">{{ b2pElementsCount }} шт</span>
      </div>
    </li>
    <li
      class="vPlugFilterList__item"
      :class="{ active: activeFilter.value === 'cloud' }"
      @click="selectByFilter('cloud')"
    >
      <div class="vPlugFilterList__ico">
        <img src="~@/assets/images/cloud-ico.svg">
      </div>
      <div class="vPlugFilterList__title">
        Выбрать только
      </div>
      <div class="vPlugFilterList__info">
        <span class="vPlugFilterList__type">CloudTips:</span>
        <span
          class="vPlugFilterList__count"
        >{{ cloudElementsCount.value }} шт</span>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'VPlugFilterList',
  inject: [
    'allElementsCount',
    'cloudElementsCount',
    'b2pElementsCount',
    'activeFilter',
  ],
  emits: ['selectByFilter'],
  methods: {
    selectByFilter(type) {
      this.$emit('selectByFilter', type)
    },
  },
}
</script>

<style lang="scss" scoped>
.vPlugFilterList {
  display: flex;
  align-items: center;
  margin-left: -8px;
  &__item {
    background: #fff;
    border: 1px solid #dcdee3;
    border-radius: 8px;
    margin-left: 8px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: auto;
    border: 1px solid #fff;
    cursor: pointer;
    &:hover,
    &.active {
      border-color: #6764ff;
    }
  }
  &__title {
    color: #010d35;
    font-weight: 500;
    font-size: 10px;
  }
  &__info {
    font-weight: 500;
    font-size: 10px;
  }
  &__type {
    color: #010d35;
    padding-right: 3px;
  }
  &__count {
    color: #80869a;
  }
}
</style>
