<template>
  <div class="vPlugDropdown">
    <div class="vPlugDropdown__title">
      Ресторан
    </div>
    <div class="vPlugDropdown__block">
      <vPlugSearch @onInputSearchElement="onInputSearchElement" />
    </div>
    <div class="vPlugDropdown__block">
      <vPlugFilterList @selectByFilter="selectByFilter" />
    </div>
    <div class="vPlugDropdown__block">
      <vPlugLst
        :selected-elements="selectedElements"
        @selectElement="selectElement"
      />
    </div>
    <div class="vPlugDropdown__footer">
      <vButton
        :disabled="!checkSelectedData.value || !changeData.value"
        @click="onCheckSelectedData(false)"
      >
        Применить
      </vButton>
      <vButtonRed
        :disabled="selectedElementsCount.value < 1"
        @click="resetSelectedElements"
      >
        Сбросить всё
      </vButtonRed>
    </div>
  </div>
</template>

<script>
import vPlugSearch from '@/components/profile/plugForm/v-plug-search.vue'
import VPlugFilterList from '@/components/profile/plugForm/v-plug-filter-list.vue'
import vPlugLst from '@/components/profile/plugForm/v-plug-list.vue'
import vButton from '@/components/v-button.vue'
import vButtonRed from '@/components/v-button-red.vue'
export default {
  name: 'VPlugDropdown',
  components: {
    vPlugSearch,
    VPlugFilterList,
    vPlugLst,
    vButton,
    vButtonRed,
  },
  inject: ['selectedElementsCount', 'checkSelectedData', 'changeData'],
  props: {
    selectedElements: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  emits: [
    'onInputSearchElement',
    'selectElement',
    'selectByFilter',
    'resetSelectedElements',
    'onCheckSelectedData',
  ],
  methods: {
    onInputSearchElement(val) {
      this.$emit('onInputSearchElement', val)
    },
    selectElement(id) {
      this.$emit('selectElement', id)
    },
    selectByFilter(type) {
      this.$emit('selectByFilter', type)
    },
    onCheckSelectedData(val) {
      this.$emit('onCheckSelectedData', val)
    },
    resetSelectedElements() {
      this.$emit('resetSelectedElements')
    },
  },
}
</script>

<style lang="scss" scoped>
.vPlugDropdown {
  position: absolute;
  left: 0;
  top: calc(100% + 8px);
  width: 100%;
  border: 1px solid #e6e7eb;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  background: #fff;
  padding: 16px;
  &__title {
    color: #010d35;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 20px;
  }
  &__block {
    margin-bottom: 12px;
  }
  &__footer {
    display: flex;
    & > *:first-child {
      margin-right: 6px;
    }
    & > *:last-child {
      margin-left: 6px;
    }
  }
}
</style>
