<template>
  <div class="vPlugSearch">
    <img
      src="~@/assets/images/search_gray.svg"
      class="vPlugSearch__ico"
    >
    <input
      v-model="search"
      type="text"
      placeholder="Введите название ресторана"
      class="vPlugSearch__input"
      @input="onInputSearchElement($event.target.value)"
    >
    <button
      v-show="search.length"
      class="vPlugSearch__btn"
      @click="reset"
    >
      Сбросить
    </button>
  </div>
</template>

<script>
export default {
  name: 'VPlugSearch',
  emits: ['onInputSearchElement'],
  data() {
    return {
      search: '',
    }
  },
  methods: {
    onInputSearchElement(val) {
      this.$emit('onInputSearchElement', val)
    },
    reset() {
      this.search = ''
      this.$emit('onInputSearchElement', '')
    },
  },
}
</script>

<style lang="scss" scoped>
.vPlugSearch {
  position: relative;
  display: flex;
  align-items: center;
  &__input {
    width: 100%;
    border: 1px solid #e6e7eb;
    border-radius: 6px;
    background: #ffffff;
    height: 30px;
    padding: 0 15px 0 36px;
    &:focus {
      border-color: #6764ff;
    }
    &::placeholder {
      color: #80869a;
      font-size: 13px;
      font-weight: 300;
    }
  }
  &__ico {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
  }
  &__btn {
    border: 1px solid #ec4e4e;
    border-radius: 6px;
    margin-left: 8px;
    height: 30px;
    width: 86px;
    font-weight: 600;
    font-size: 14px;
    color: #ec4e4e;
    background: rgba(254, 195, 195, 0.15);
    cursor: pointer;
  }
}
</style>
