<template>
  <div
    ref="vPlugSelect"
    class="vPlugSelect"
    :class="{ active: activeDropdown }"
  >
    <div
      class="vPlugSelect__clickArea"
      @click="toggleDropdown"
    >
      <div class="vPlugSelect__placeholder">
        {{ placeholder }}
      </div>
      <div class="vPlugSelect__value">
        Выбрано: {{ selectedElementsId.value.length }} шт
      </div>
      <img
        src="~@/assets/images/arrow_select.svg"
        class="vPlugSelect__arrow"
      >
    </div>
    <vPlugDropdown
      v-show="activeDropdown"
      :selected-elements="selectedElements"
      @onInputSearchElement="onInputSearchElement"
      @selectElement="selectElement"
      @selectByFilter="selectByFilter"
      @resetSelectedElements="resetSelectedElements"
      @onCheckSelectedData="onCheckSelectedData"
    />
  </div>
</template>

<script>
import vPlugDropdown from '@/components/profile/plugForm/v-plug-dropdown.vue'
export default {
  name: 'VPlugSelect',
  components: {
    vPlugDropdown,
  },
  inject: ['selectedElementsId'],
  props: {
    selectedElements: {
      type: Array,
      required: true,
      default: () => [],
    },
    activeDropdown: {
      type: Boolean,
      required: true,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Где заглушить?',
    },
  },
  emits: [
    'onInputSearchElement',
    'selectElement',
    'selectByFilter',
    'resetSelectedElements',
    'onCheckSelectedData',
    'toggleActiveDropdown',
  ],
  mounted() {
    document.addEventListener('click', this.outsideClick)
  },
  unmounted() {
    document.removeEventListener('click', this.outsideClick)
  },
  methods: {
    onInputSearchElement(val) {
      this.$emit('onInputSearchElement', val)
    },
    selectElement(id) {
      this.$emit('selectElement', id)
    },
    selectByFilter(type) {
      this.$emit('selectByFilter', type)
    },
    resetSelectedElements() {
      this.$emit('resetSelectedElements')
    },
    onCheckSelectedData(val) {
      this.$emit('onCheckSelectedData', val)
    },
    toggleDropdown() {
      this.$emit('toggleActiveDropdown', true)
    },
    outsideClick(e) {
      if (!e.composedPath().includes(this.$refs.vPlugSelect)) {
        this.$emit('toggleActiveDropdown', false)
        this.onCheckSelectedData(false)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.vPlugSelect {
  border: 1px solid #e6e7eb;
  border-radius: 12px;
  height: 52px;
  width: 100%;
  background: #fff;
  margin-bottom: 16px;
  position: relative;
  z-index: 3;
  &.active {
    border-color: #6764ff;
    .vPlugSelect__arrow {
      transform: translateY(-50%) rotate(180deg);
    }
  }
  &__placeholder {
    color: #80869a;
    font-weight: 400;
    font-size: 12px;
    position: absolute;
    left: 12px;
    top: 8px;
  }
  &__arrow {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
  }
  &__value {
    color: #010d35;
    font-weight: 500;
    font-size: 16px;
  }
  &__clickArea {
    cursor: pointer;
    padding-left: 12px;
    padding-top: 24px;
  }
}
</style>
